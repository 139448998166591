import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Partnership = ({
  items,
  primary
}) => {
  const {
    content,
    image,
    title,
    title_color,
    background_color,
    image_position
  } = primary;
  const left = image_position === 'Left';
  return (
    <section style={{backgroundColor: background_color}}
      className="py-24">
        <div className="xl:container mx-auto px-6">
          <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-20 w-full">

            <div className={`lg:col-span-2 mb-6 ${left ? 'text-left' : 'lg:text-right'}`}>
              <h3 className="font-black text-3xl md:text-4xl mb-2"
                style={{color: title_color}}>{title}</h3>
              <div className={`border border-solid md:max-w-[60%] ${left ? '' : 'lg:ml-[40%]'}`}
                style={{borderColor: title_color}}></div>
            </div>

            <div className={left ? 'order-1' : 'order-2'}>
              <GatsbyImage image={getImage(image)}
                alt={image.alt || ''}
                className="mx-auto rounded-3xl" />
            </div>
            <div className={`grid md:grid-cols-2 items-start gap-y-6 ${left ? 'order-2' : 'order-1'}`}>
              <div className="grid gap-y-4 mb-6 md:col-span-2"
                dangerouslySetInnerHTML={{__html: content.html}}></div>
              {items.map((i,k) => (
                <React.Fragment key={k}>
                  {i.image.gatsbyImageData !== null &&
                    <div className="md:col-span-2">
                      <div className="w-full flex">
                        <GatsbyImage image={getImage(i.image)}
                          alt={i.image.alt || ''}
                          className="mx-auto max-w-[200px] md:max-w-[300px]" />
                      </div>
                    </div>
                  }
                  {i.stat_value !== null &&
                    <div className={`${i.full_width ? 'md:col-span-2' : ''}`}>
                      <p className="font-black text-3xl"
                        style={{color: i.stat_value_color}}>{i.stat_value}</p>
                      <p className="text-lg"
                        style={{color: i.stat_caption_color}}>{i.stat_caption}</p>
                    </div>
                  }
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
    </section>
  )
}

export default Partnership;