import React from "react";
import Cta from "./Cta";
import Button from './Button';

const GetStartedBanner = ({image}) => {
  return (
    <Cta bg="sourci-pink"
      image={image}>
      <div className="text-center text-white w-full">
        <p className="text-4xl md:text-5xl font-black">Go ahead,</p>
        <p className="text-4xl md:text-5xl font-black mb-6">Hit the <span className="text-black">start button</span></p>
        <Button label="Get in touch today"
          to="/contact"
          type="link"
          className="bg-sourci-teal hover:bg-sourci-teal-100 transition-colors text-white text-lg md:text-xl font-black" />
      </div>
    </Cta>
  )
}

export default GetStartedBanner;