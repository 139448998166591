import React from 'react';
import clsx from 'clsx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Cta = ({
  bg = 'white',
  image,
  children,
  style = {}
}) => {
  const sectionClass = clsx(`bg-${bg}`)
  return (
    <section className={sectionClass}
      style={style}>
      <div className={clsx('relative overflow-hidden w-full h-full', {
        'py-24': image,
        'py-20': !image
      })}>
        {image &&
          <div className="absolute block w-full top-0 left-0 h-full">
            <GatsbyImage image={getImage(image)}
              alt={image.alt || ''}
              placeholder="blurred"
              className="block w-full h-full" />
          </div>
        }
        <div className="relative xl:container mx-auto px-6">
          {children}
        </div>
      </div>
    </section>
  )
}

export default Cta;