import React from 'react';
import { Seo } from "../components/Seo";
import {graphql} from 'gatsby';
import GetStartedBanner from '../components/GetStartedBanner';
import Partnership from '../components/Partnership';
import PageBanner from '../components/PageBanner';

const CommunityPartnershipsPage = ({data}) => {
  const prismicData = data.prismicCommunityPartnerships.data;
  const pageBannerData = {
    title: prismicData.page_title,
    titleColor: prismicData.page_title_color,
    titleParts: prismicData.page_banner_title_parts,
    paragraph: prismicData.page_banner_paragraph.html,
    paragraphColor: prismicData.page_banner_paragraph_color,
    backgroundColor: prismicData.page_banner_background_color,
    image: prismicData.page_banner_image,
    imageStyle: 'mx-auto max-w-[200px] md:max-w-[300px] lg:max-w-[400px]'
  };
  const partnerships = prismicData.body.filter(e => e.slice_type === 'partnership');

  return (
    <main>
      <PageBanner {...pageBannerData} />
      {partnerships.map((i,k) => (
        <Partnership {...i}
          key={i.id} />
      ))}
      <GetStartedBanner image={prismicData.cta_banner_image} />
    </main>
  )
}

export const query = graphql`
  query {
    prismicCommunityPartnerships {
      data {
        body {
          ... on PrismicCommunityPartnershipsDataBodyPartnership {
            id
            slice_type
            items {
              full_width
              stat_caption
              stat_caption_color
              stat_value
              stat_value_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
            primary {
              content {
                html
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title
              title_color
              image_position
              background_color
            }
          }
        }
        meta_description
        meta_title
        page_banner_background_color
        page_banner_paragraph_color
        page_title
        page_title_color
        page_banner_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
        page_banner_paragraph {
          html
        }
        page_banner_title_parts {
          color
          text
        }
        cta_banner_image {
          gatsbyImageData(placeholder: BLURRED)
          alt
        }
      }
    }
  }
`

export default CommunityPartnershipsPage

export function Head({data}) {
  const prismicData = data.prismicCommunityPartnerships.data;
  return (
    <Seo title={prismicData.meta_title}
      description={prismicData.meta_description}
      canonical="/community-partnerships" />
  )
}