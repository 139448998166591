import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import clsx from "clsx";

const PageBanner = ({
  title,
  titleColor,
  titleParts,
  captionParts,
  paragraph,
  paragraphColor,
  backgroundColor,
  paragraphParts,
  image = false,
  imageStyle,
  video = false,
  videoPlaceholder,
  titlePartsSize = 'text-4xl lg:text-5xl',
  captionPartSize = 'text-2xl lg:text-3xl'
}) => {
  return (
    <section style={{backgroundColor: backgroundColor}}
      className="py-24">
      <div className="xl:container mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-y-6 md:gap-x-20 gap-x-60 w-full justify-center">
          <div className="mb-12 lg:mb-0">
            <h1 className="font-black mb-8 md:mb-12 text-3xl lg:text-4xl"
              style={{color: titleColor}}>{title}</h1>
            <p className={clsx('font-black mb-8', titlePartsSize)}>
              {titleParts.map((i,k) => (
                <span style={{color: i.color}}
                  key={k}
                  className={clsx(i.new_line && 'block')}>{i.text} </span>
              ))}
            </p>
            {captionParts &&
              <p className={clsx('font-black mb-8 md:mb-12', captionPartSize)}>
                {captionParts.map((i,k) => (
                  <span style={{color: i.color}}
                    key={k}
                    className={clsx(i.new_line && 'block')}>{i.text} </span>
                ))}
              </p>
            }
            {paragraphParts
              ? <>{paragraphParts.map((block, k) => (
                <p className="text-xl md:text-2xl mb-6"
                  key={k}>
                  {block.map((i,k) => (
                    <span style={{color: i.color}}
                      className={clsx(i.bold_text && 'font-bold')}
                      key={k}>
                        {i.text.text}
                    </span>
                  ))}
                </p>
                ))}</>
              : <div className="text-xl md:text-2xl font-black"
              style={{color: paragraphColor}}
              dangerouslySetInnerHTML={{__html: paragraph}}></div>
            }
            
          </div>

          {image &&
            <div>
              <div className="mx-auto flex">
                <GatsbyImage image={getImage(image)}
                  alt={image.alt || ''}
                  className={imageStyle} />
              </div>
            </div>
          }
          {video &&
            <div>
              <div className="mx-auto flex overflow-hidden rounded-3xl relative pt-[56.25%]">
                <ReactPlayer url={video}
                  light={videoPlaceholder}
                  controls
                  className="absolute top-0 left-0"
                  width="100%"
                  height="100%"
                  config={{
                    youtube: {
                        playerVars: {
                            autoplay: 1,
                            modestbranding: 1,
                        },
                    },
                  }} />
              </div>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default PageBanner;